<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  #Support Title: {{supportOnlineExam.name}}  <span v-bind:class="{ 'badge badge-danger': supportOnlineExam.is_closed, 'badge badge-success': !supportOnlineExam.is_closed }">{{ !supportOnlineExam.is_closed ? 'In Progress' : 'Closed' }}</span>
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 search-wrapper">
                <h4>Enroller's details </h4>
                <div class="row mt-3 mx-1">
                  <div class="col-md-4">
                    <div class="row mb-3">
                      <b>Enroller's name: </b>
                      <span class="ml-2">{{supportOnlineExam.account_holder_full_name ?  supportOnlineExam.account_holder_full_name : 'NA'}}</span>
                    </div>
                    <div class="row mb-3">
                      <b>Reply email: </b> <span class="ml-2">{{supportOnlineExam.reply_email ?  supportOnlineExam.reply_email : 'NA'}}</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row mb-3">
                      <b>Phone No: </b> <span class="ml-2">{{supportOnlineExam.account_holder_phone}} {{supportOnlineExam.account_holder_mobile}}</span>
                    </div>
                    <div class="row mb-3">
                      <b>Address: </b> <span class="ml-2">{{supportOnlineExam.account_holder_address_line_1}}</span>
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="row mb-3">
                      <b>Suburb/Town: </b> <span class="ml-2">{{supportOnlineExam.account_holder_city ? supportOnlineExam.account_holder_city : 'N/A'}}</span>
                    </div>
                    <div class="row mb-3">
                      <b>State: </b>  <span class="ml-2">{{supportOnlineExam.account_holder_state}}</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row mb-3">
                      <b>Country: </b>  <span class="ml-2">{{supportOnlineExam.account_holder_country ? supportOnlineExam.account_holder_country : 'NA'}}</span>
                    </div>
                    <div class="row mb-3">
                      <b>Post code: </b> <span class="ml-2">{{supportOnlineExam.account_holder_post_code ? supportOnlineExam.account_holder_post_code: 'NA'}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 my-2 search-wrapper">
                <h4>Candidate's details</h4>

                <div class="row mt-3 mx-1">
                    <div class="col-md-4">
                      <div class="row mb-3">
                        <b>Candidate's name: </b>  <span class="ml-2">{{supportOnlineExam.candidate_full_name }}</span>
                      </div>
                      <div class="row mb-3">
                        <b>Enrolment key: </b>  <a class="ml-2" v-copy="supportOnlineExam.candidate_exam_key">{{supportOnlineExam.candidate_exam_key }}</a>
                      </div>
                      <div class="row mb-3">
                        <b>Product type: </b>  <span class="ml-2">{{supportOnlineExam.candidate_product_type }}</span>
                      </div>
                      <div class="row mb-3">
                        <b>AMEB candidate number: </b>  <span class="ml-2">{{supportOnlineExam.candidate_ameb_candidate_no }}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="row mb-3">
                        <b>Date of birth: </b>  <span class="ml-2">{{supportOnlineExam.candidate_date_of_birth ? supportOnlineExam.candidate_date_of_birth : 'N/A' }}</span>
                      </div>
                      <div class="row mb-3">
                        <b>Gender:</b>  <span class="ml-2">{{supportOnlineExam.candidate_gender ? supportOnlineExam.candidate_gender : 'N/A'}}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="row mb-3">
                        <b>Enrolment status: </b>  <span class="ml-2">{{supportOnlineExam.candidate_enrolment_status }}</span>
                      </div>
                      <div class="row mb-3">
                        <b>Expiry date: </b>  <span class="ml-2">{{supportOnlineExam.candidate_enrolment_expiry_date }}</span>
                      </div>
                    </div>
                  </div>
              </div>

              <div class="col-md-12">
                <b>Computer type : </b>{{supportOnlineExam.device_type}} <br>

                <b>Operating system: </b> {{supportOnlineExam.device_os}} <br>

                <b>Browser: </b> {{supportOnlineExam.device_browser}} <br>

                <b>Exam name: </b>{{supportOnlineExam.candidate_product_name ? supportOnlineExam.candidate_product_name : 'N/A'}} <br>

                <b>Attempt ID: </b>{{supportOnlineExam.attempt_id ? supportOnlineExam.attempt_id : 'N/A'}} <br>

                <b>External Id: </b>{{supportOnlineExam.external_key ? supportOnlineExam.external_key : 'N/A'}} <br>

                <b>Problem description: </b><span v-html="supportOnlineExam.description"></span> <br>

                <div v-if="supportOnlineExam.general_comment">
                  <b>General comments: </b><span v-html="supportOnlineExam.general_comment"></span>
                </div>
                <br>

               <div v-if="supportOnlineExam.error_on_question">
                 <b>Error question: </b><span>{{supportOnlineExam.error_on_question}}</span> <br>
               </div>
              </div>

              <div class="col-md-12" v-if="supportOnlineExam && supportOnlineExam.attachments && supportOnlineExam.attachments.length > 0">
                <h6>
                  Attachments:
                </h6>
                <v-row
                    class="ml-2 mb-2 mt-2"
                >
                  <v-col
                      v-for="(attachment, index) in supportOnlineExam.attachments"
                      :key="index"
                      class="d-flex child-flex"
                      cols="1"
                  >
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'image'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                      <v-img
                          :src="attachment.file_path.original"
                          :lazy-src="attachment.file_path.original"
                          aspect-ratio="1"
                          max-width="100"
                          max-height="100"
                          class="grey lighten-2"
                      >
                      </v-img>
                    </a>
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'pdf'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                      <v-img
                          src="/media/image/pdf.png"
                          lazy-src="/media/image/pdf.png"
                          aspect-ratio="1"
                          max-width="100"
                          max-height="100"
                          class="grey lighten-2"
                      >
                      </v-img>
                    </a>
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'doc'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                      <v-img
                          src="/media/image/word.png"
                          lazy-src="/media/image/word.png"
                          aspect-ratio="1"
                          max-width="100"
                          max-height="100"
                          class="grey lighten-2"
                      >
                      </v-img>
                    </a>
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'xls'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                      <v-img
                          src="/media/image/xlsx.png"
                          lazy-src="/media/image/xlsx.png"
                          aspect-ratio="1"
                          max-width="100"
                          max-height="100"
                          class="grey lighten-2"
                      >
                      </v-img>
                    </a>
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'cvs'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                      <v-img
                          src="/media/image/cvs.png"
                          lazy-src="/media/image/cvs.png"
                          aspect-ratio="1"
                          max-width="100"
                          max-height="100"
                          class="grey lighten-2"
                      >
                      </v-img>
                    </a>
                    <v-btn
                        v-if="supportOnlineExam.is_draft"
                        class="deleteBtn"
                        @click="deleteConfirm(attachment.id)"
                        absolute
                        rounded
                        right
                        top
                        x-small
                    >
                      <v-icon small color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="mt-2 w-full" v-if="supportOnlineExam.reply_response">
              <div class=" items-center text-sm">
                <div class="rounded-md bg-gray-100 p-4 mb-10">
                  Replied by: <span class="font-size-sm text-black badge badge-primary">{{supportOnlineExam.replied_by ? supportOnlineExam.replied_by : '' }}</span>
                  <div class="flex">
                    <div class="ml-3">
                      <div class="mt-2 text-sm text-gray-700">
                        <p v-html="supportOnlineExam.reply_response"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="mt-2 w-full" v-if="supportOnlineExam.resolution_comment">
              <div class=" items-center text-sm">
                <div class="rounded-md bg-gray-100 p-4 mb-10">
                  Closed by: <span class="font-size-sm text-black badge badge-primary">{{supportOnlineExam.closed_by ? supportOnlineExam.closed_by : '' }}</span>
                  <v-col cols="12">
                    <h6><v-btn color="primary" small v-if="supportOnlineExam.is_draft" @click="editDraft">Edit Draft</v-btn></h6>
                    <span class="badge badge-primary" v-if="supportOnlineExam.is_draft">Draft</span>
                  </v-col>
                  <div class="flex">
                    <div class="ml-3">
                      <div class="mt-2 text-sm text-gray-700">
                        <span v-html="supportOnlineExam.resolution_comment"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="mt-5">

              <v-row v-if="!supportOnlineExam.is_closed && !supportOnlineExam.is_replied">
                <v-col cols="12">
                  <h6>Reply response:</h6>
                  <ckeditor
                      :config="editorConfigReply"
                      v-model="online_exam_reply.reply_response"
                  >
                  </ckeditor>
                  <span
                      class="text-danger"
                      v-if="$v.online_exam_reply.reply_response.$error"
                  >This information is required</span>

                </v-col>

              </v-row>
            </div>

            <div class="mt-5">
              <v-row v-if="!supportOnlineExam.is_closed">
                <v-col cols="12">
                  <h6>Closing comment:</h6>
                  <ckeditor
                      :config="editorConfigClose"
                      v-model="online_exam_close.resolution_comment"
                  >
                  </ckeditor>
                  <span
                      class="text-danger"
                      v-if="$v.online_exam_close.resolution_comment.$error"
                  >This information is required</span
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <div>
                    <v-file-input
                        label="Attachment files"
                        v-model="online_exam_reply.files"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        multiple
                        clearable
                        counter
                        :show-size="1000"
                        outlined
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                            v-if="index < 4"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                            v-else-if="index === 4"
                            class="text-overline grey--text text--darken-3 mx-2"
                        >
                                                +{{ online_exam_reply.files.length - 4 }} file(s)
                                            </span>
                      </template>
                    </v-file-input>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-btn
                      v-if="!supportOnlineExam.is_closed"
                      @click="saveAsDraft"
                      class="btn btn-primary"
                      dark
                      x-large
                  >Save draft</v-btn
                  >
                  <div class="text-right">
                    <v-btn
                       v-if="!supportOnlineExam.is_closed"
                        @click="closed"
                        class="mr-3 cancel-btn"
                        color="red"
                        dark
                        x-large

                    >Close ticket</v-btn>
                    <v-btn
                        v-if="!supportOnlineExam.is_closed"
                        @click="reply"
                        :loading="loading"
                        class="btn btn-primary text-white"
                        x-large
                    >Reply</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import {required,requiredIf} from "vuelidate/lib/validators";
import SupportOnlineExamService from "@/services/support/support-online-exam/SupportOnlineExamService";
import SupportOnlineExamAttachmentService
  from "@/services/support/support-online-exam/attachement/SupportOnlineExamAttachmentService";

const supportOnlineExam = new SupportOnlineExamService();
const attachmentService = new SupportOnlineExamAttachmentService();
export default {
  name: "View",
  data() {
    return {
      loading: false,
      onlineExamId: null,
      supportOnlineExam: {},
      online_exam_reply: {
        files: [],
        reply_response:'',
        resolution_comment: ''
      },

      online_exam_close: {
        files: [],
        reply_response:'',
        resolution_comment: ''
      },
      editorConfigReply: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
        versionCheck: false
      },

      editorConfigClose: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
        versionCheck: false
      },
    }
  },
  validations: {
    online_exam_reply: {
      resolution_comment: {},
      reply_response: {required}
    },

    online_exam_close: {
      resolution_comment: {required},
      reply_response: {}
    }
  },
  mounted() {
    this.onlineExamId = this.$route.params.onlineExamId;
    this.getSupportOnlineExamDetail();
  },
  methods: {
    getSupportOnlineExamDetail() {
      supportOnlineExam.show(this.onlineExamId).then(response => {
        this.supportOnlineExam = response.data.supportOnlineExam;
      }).catch(() => {})
    },
    editDraft() {
      this.online_exam_close.resolution_comment = this.supportOnlineExam.resolution_comment;
    },
    saveAsDraft() {
      this.online_exam_close.is_draft = true;
      this.update();
    },
    reply() {
      this.online_exam_close.is_draft = false;
      this.update();
    },
    convertToFormData() {
      let formData = new FormData();
      if (this.online_exam_reply.files && this.online_exam_reply.files.length > 1) {
        for (const file in this.online_exam_reply.files) {
          if (
              this.online_exam_reply.files[file] != null &&
              this.online_exam_reply.files[file] !== undefined
          ) {
            formData.append(`files[${file}]`, this.online_exam_reply.files[file]);
          }
        }
      }
      if (this.online_exam_reply.files.length == 1) {
        formData.append("files", this.online_exam_reply.files[0]);
      }
      for (let key in this.online_exam_reply) {
        if (key != "files" && this.online_exam_reply[key] && key != "thread_attachments") {
          formData.append(key, this.online_exam_reply[key]);
        }
      }

      if (this.online_exam_close.files && this.online_exam_close.files.length > 1) {
        for (const file in this.online_exam_close.files) {
          if (
              this.online_exam_close.files[file] != null &&
              this.online_exam_close.files[file] !== undefined
          ) {
            formData.append(`files[${file}]`, this.online_exam_close.files[file]);
          }
        }
      }
      if (this.online_exam_close.files.length == 1) {
        formData.append("files", this.online_exam_close.files[0]);
      }
      for (let key in this.online_exam_close) {
        if (key != "files" && this.online_exam_close[key] && key != "thread_attachments") {
          formData.append(key, this.online_exam_close[key]);
        }
      }
      return formData;
    },
    update() {
      this.$v.online_exam_reply.$touch()
      if (this.$v.online_exam_reply.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }else {
        this.loading = true;
        let formData = this.convertToFormData();
        supportOnlineExam.update(this.onlineExamId, formData).then(response => {
          this.$snotify.success("Reply sent");
          this.$v.$reset();
          this.loading = false;
          this.getSupportOnlineExamDetail();
          this.online_exam = {
            files: [],
            resolution_comment: ''
          };
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    deleteConfirm(id) {
      this.$confirm({
        message: `Are you sure ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            attachmentService.delete(id).then(response => {
              this.$snotify.success("Attachment removed");
              this.getSupportOnlineExamDetail();
            }).catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },
    closed() {
      this.$v.online_exam_close.$touch()
      if (this.$v.online_exam_close.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }else {
        this.$confirm({
          message: `Do you want to close this ticket?`,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              let formData = this.convertToFormData();
              supportOnlineExam.closed(this.onlineExamId, formData).then(response => {
                this.$snotify.success("Ticket Closed");
                this.supportOnlineExam = {};
                this.$v.$reset();
                this.loading = false;
                this.online_exam = {
                  files: [],
                  resolution_comment: ''
                };
                this.$tabs.close();
              }).catch(() => {
                this.loading = false;
              });
            }
          },
        });
      }

    }
  }
}
</script>