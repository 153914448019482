import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SupportOnlineExamAttachmentService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/support-online-exam/attachment';
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.post(url);
    }
}